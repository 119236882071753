<template>
  <div class="account-created">
    <div class="container">
      <h3>{{ mensagem }}</h3>
      <p>{{ complemento }}</p>
      <alert-error v-if="error">
        <template v-slot:alert-error>
          {{msgError}}
        </template>
      </alert-error>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Account-created',
  mounted(){
     this.payload = {
      "auth_code": this.$route.query.auth_code,
      "code": this.$route.query.code,
      "state": this.$route.query.state
     }

     this.$http.get('tiktok/auth/callback', this.payload)
      .then(res => {
        this.mensagem = res.data.message
        this.complemento = 'Em breve você terá acesso ao dashboard para acompanhar o desempenho dos seus anúncios'
      }).catch(error => {
        if (error.response) {
          this.msgError = error.response.data.message;
          this.error = true;
          this.mensagem = error.response.data.message
        }
      });
    },
  data(){
   return{
     mensagem: 'Finalizando integração...',
     complemento: null,
     error: false,
     msgError: ""
    }
  }
}
</script>
